<template>
  <div>
    <scroll-bar>
      <template v-if="!isCollapse">


        <img :src="ydtd_logo" style="width: 160px;" />
        <!-- <div class="logo" v-if="domain == 'admin.adwanji.com'">
        <img :src="mc_logo" style="width: 160px;" />
        </div>
        <div class="logo" v-else-if="domain == 'adm.adbaixiang.com'">
        <img :src="bxg_logo" style="width: 160px;" />
        </div>
        <div class="logo" v-else-if="domain == 'ydtd.adwanji.com'">
        <img :src="ydtd_logo" style="width: 160px;" />
        </div> -->
        <!-- <div class="logo" v-else> -->
        <!-- <img :src="bxg_logo" style="width: 180px;" />
        <img :src="mc_logo" style="width: 160px;" /> -->
        <!-- </div> -->
      </template>
      <el-menu mode="vertical" :show-timeout="200" :default-active="$route.path" :collapse="isCollapse"
        background-color="#304156" text-color="#efefef" active-text-color="#409EFF">
        <sidebar-item :routes="permission_routes"></sidebar-item>
      </el-menu>

    </scroll-bar>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import ScrollBar from "@/components/ScrollBar";
import mc_logo from '@/assets/meicheng/logo2.png';
import bxg_logo from '@/assets/baixiangguo/logo2.png';
import ydtd_logo from '@/assets/ydtd/logo4.png';
export default {
  components: { SidebarItem, ScrollBar },
  data() {
    return {
      mc_logo, bxg_logo, ydtd_logo
    }
  },
  computed: {
    ...mapGetters(['permission_routes', "sidebar"]),
    routes() {
      console.log("this.$router.options.routes", this.$router.options.routes);
      return this.$router.options.routes;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },

    domain: function () {
      return window.location.host;
    }


  }
};
</script>
<style>
.logo {
  color: white;

  padding: 20px 0px;
  text-align: center;
}

.logo .title {
  font-size: 28px;
  color: #efefef;
  letter-spacing: 15px;
}

.logo .subtitle {
  font-size: 14px;
  color: white;
  line-height: 20px;
  letter-spacing: 6px;
}
</style>
