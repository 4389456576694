
import emun from "@/utils/emun";
const money = function (val, nullStr) {
  // 截取当前数据到小数点后两位
  // let realVal = Number(val).toFixed(2)
  // num.toFixed(2)获取的是字符串
  // return Number(realVal)
  if (isNaN(val)) {
    val = null;
  }

  if (val || val == 0) {
    val = val.toString().replace(/\$|\,/g, "");
    if (isNaN(val)) {
      val = "0";
    }
    let sign = val == (val = Math.abs(val));
    val = Math.floor(val * 100 + 0.50000000001);
    let cents = val % 100;
    val = Math.floor(val / 100).toString();
    if (cents < 10) {
      cents = "0" + cents;
    }
    for (var i = 0; i < Math.floor((val.length - (1 + i)) / 3); i++) {
      val =
        val.substring(0, val.length - (4 * i + 3)) +
        "," +
        val.substring(val.length - (4 * i + 3));
    }
    return (sign ? "" : "-") + val + "." + cents;
  } else {
    if (!nullStr) nullStr = "-";
    return nullStr;
  }
}
const bigMonery = function (n) {
  var fraction = ["角", "分"];
  var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  var unit = [["元", "万", "亿"], ["", "拾", "佰", "仟"]];
  var head = n < 0 ? "欠" : "";
  n = Math.abs(n);

  var s = "";

  for (var i = 0; i < fraction.length; i++) {
    s += (
      digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
    ).replace(/零./, "");
  }
  s = s || "整";
  n = Math.floor(n);

  for (var i = 0; i < unit[0].length && n > 0; i++) {
    var p = "";
    for (var j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整")
  );
}

const fNull = function (value, nullStr) {
  if (value != null) return value;
  return nullStr ? nullStr : "-";
};
const fBool = function (value, strT, strF, nullStr) {
  if (value == true) return strT ? strT : "是";
  if (value == false) return strF ? strF : "否";
  return nullStr ? nullStr : "-";
};

///返回管理团队名称
const getMTeam = function (orgName) {
  let aa = orgName.split("-");
  let str = orgName.replace("-" + aa[aa.length - 1], "");
  return str;
};
///返回业务团队名称
const getBTeam = function (orgName) {
  if (orgName) {
    let aa = orgName.split("-");
    return aa[aa.length - 1];
  }
};
const shortDate = function (val) {
  if (val) {
    return val.substring(0, 10);
  }
};

const percent = function (val) {
  if (val != null && val != "-") {
    //console.log(val);
    // val = parseFloat(val.substring(0, val.length - 1));
    val = val.toFixed(2);
    return val + " %";
  } else {
    return "-";
  }
};
const fixed = function (val, num) {
  if (val != null && val != "-") {
    //console.log(val);
    val = parseFloat(val);
    val = val.toFixed(2);
    return val;
  } else {
    return "-";
  }
};
const wordRep = (val) => {
  if (val) {
    val = val.replace(/\r\n/g, "<br>");
    val = val.replace(/\n/g, "<br>");
    return val;
  }
}

const statusFilter = function (value) {
  var name = "_";
  emun.slotStatus.forEach(element => {
    if (element.id === value) name = element.name;
  });
  return name;
}
const typeFilter = function (value) {
  var name = "_";
  emun.slotTypes.forEach(element => {
    if (element.id === value) name = element.name;
  });
  return name;
}
const appTypeFilter = function (value) {
  var name = "_";
  emun.appTypes.forEach(element => {
    if (element.id === value) name = element.name;
  });
  return name;
}
const auditStatusFilter = function (value) {
  var name = "_";
  emun.auditStatus.forEach(element => {
    if (element.id === value) name = element.name;
  });
  return name;
}
const slotSettingStatusFilter = function (value,flag) {
  var name = {name:"-", type: "warning"};
  emun.slotSettingStatus.forEach(element => {
    if (element.id === value) name = element;
  });
  return name[flag];
}
export default {
  money,///格式化金额
  bigMonery,///格式化大写金额
  fNull,///格式化空
  fBool,
  getMTeam,
  getBTeam,
  shortDate,///格式化短日期
  percent,
  wordRep,
  fixed,
  statusFilter,
  typeFilter,
  appTypeFilter,
  auditStatusFilter,
  slotSettingStatusFilter

}